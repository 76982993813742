import React, { Component } from "react";
import { Auth, API, Storage } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import Form from "react-bootstrap/Form";
//import FormCheck from "react-bootstrap/FormCheck";
//import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./KbArticle.css";
// Using react containers to achieve responsiveness in Medias
import Container from "react-bootstrap/Container";
import Media from "react-media";

export default class NewUserRecord extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      isLoading: null,
      user: null,
      userId: "",
      userName: "",
      qatCrmId: "",
      orgType: "",
      corpName: "",
      govNumber: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      firstName: "",
      lastName: "",
      title: "",
      officePhone: "",
      mobilePhone: "",
      email: "",
      approvalCode: "",
      userRole: "",
      attachmentURL: null
    };
  }

  async componentDidMount() {

    /*
    //NOTE: Get custom:qatCrmId (and others) from cognito stored attributes
    this.getCurrUserData()
      .then((user) => {
        this.setState({
          qatCrmId: user.attributes["custom:qatCrmId"],
          userName: user.attributes["custom:qatUserName"]
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(this.state.qatCrmId);

    this.setState({
      isLoading: false
    });
    */

    try {
      let attachmentURL;
      // TODO: Need to make sure that all state data is here!!!
      const user = await this.getUser();
      const { userId, userName, qatCrmId, orgType, corpName, govNumber, streetAddress,
              city, state, zipCode, country, firstName, lastName, title, officePhone,
              mobilePhone, email, approvalCode, userRole, attachment
            } = user;

      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        user, userId, userName, qatCrmId, orgType, corpName, govNumber, streetAddress,
        city, state, zipCode, country, firstName, lastName, title, officePhone,
        mobilePhone, email, approvalCode, userRole, attachmentURL
      });
    } catch ( err ) {
      // eslint-disable-next-line
      alert("This is the error: " + err);
    }
  }

  getUser() {
    return API.get("users", `/users/${ this.props.match.params.qatCrmId }/${ this.props.match.params.userName }`);
  }

  // Validate each of the form fields in the backend
  validateForm() {
    return (this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.email.length > 0
    );
  }

  // format attachmentURL and strip the timestamp added to filename when uploaded
  formatFileName(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  saveUser(user) {
    return API.put("users", `/users/${this.props.match.params.qatCrmId}/${this.props.match.params.userName}`, {
      body: user
    });
  }

  handleSubmit = async event => {
    let attachment;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      // eslint-disable-next-line
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({
      isLoading: true
    });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
      }

      await this.saveArticle({
        userId: this.state.userId,
        userName: this.state.userName,
        qatCrmId: this.state.qatCrmId,
        orgType: this.state.orgType,
        corpName: this.state.corpName,
        govNumber: this.state.govNumber,
        streetAddress: this.state.streetAddress,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
        country: this.state.country,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        title: this.state.title,
        officePhone: this.state.officePhone,
        mobilePhone: this.state.mobilePhone,
        email: this.state.email,
        approvalCode: this.state.approvalCode,
        userRole: this.state.userRole,
        attachment: attachment || this.state.user.attachment
      });

      this.props.history.push("/");
    } catch ( err ) {
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isLoading: false
      });
    }
  }

  deleteUser() {
    return API.del("users", `/users/${this.props.match.params.qatCrmId}/${this.props.match.params.userName}`);
  }

  handleDelete = async event => {
    event.preventDefault();
    // eslint-disable-next-line
    const confirmed = window.confirm("Are you sure you want to delete this user?");

    if (!confirmed) {
      return;
    }

    this.setState({
      isDeleting: true
    });

    try {

      await this.deleteUser();
      this.props.history.push("/");

    } catch ( err ) {
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isDeleting: false
      });
    }
  }

  getIdNum(str) {
    let regex = /(\w+)[^-]*$/g;
    let match = regex.exec(str)[0];

    console.log("Here is the articleId: ", match);
    return match.toString();
  }

  render(){
  return (
      <article>
        <Media query="(min-width: 319px) and (max-width: 567px)">
          <div className="article-chrome">
            <p className="existing-article-title-sm">
              <strong>Update User Record</strong>
            </p>

            <Container className="article-record" fluid="True">
              { this.state.user &&
                <Form className="article-form" onSubmit={ this.handleSubmit }>

                  <Form.Group as={ Row } className="article-num-row" controlId="articleId">
                    <Form.Label className="article-num-label">
                      Username:
                    </Form.Label>

                    <Form.Control plaintext
                      readOnly
                      className="article-num-field"
                      value={ this.state.userName } />
                  </Form.Group>

                  <Form.Group className="article-subject-group" controlId="kbSubject">
                    <Form.Label>
                      Article Subject
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.kbSubject }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a title for this article.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="article-content-group" controlId="articleContent">
                    <Form.Label>
                      Article Content
                    </Form.Label>
                    <Form.Control as="textarea"
                      rows="10"
                      className="article-content"
                      size="lg"
                      value={ this.state.articleContent }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter the content of the article you want to create.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="kb-attachment-group" controlId="file">
                    <Form.Label>
                      Attachment
                    </Form.Label>
                    <Form.Control type="file" onChange={ this.handleFileChange } />
                    <Form.Text className="text-muted">
                      Please include any attachments as needed.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="article-btn-group">
                    <LoaderButton block
                      size="lg"
                      disabled={ !this.validateForm() }
                      variant="primary"
                      type="submit"
                      isLoading={ this.state.isLoading }
                      text="Save"
                      loadingText="Saving..."
                    />
                    { /* Add a delete button here */ }
                    <LoaderButton block
                      size="lg"
                      disabled={ !this.validateForm() }
                      variant="danger"
                      isLoading={ this.state.isDeleting }
                      onClick={ this.handleDelete }
                      text="Delete"
                      loadingText="Deleting..."
                    />
                  </Form.Group>
                </Form>
              }
            </Container>
          </div>
        </Media>

        {/* TODO: Smaller Landscapes and up */}
        <Media query="(min-width: 568px)">
          <div className="article-chrome">
            <p className="existing-article-title">
              <strong>Update User Record</strong>
            </p>

            <Container className="article-record" fluid="True">
              { this.state.user &&

                <Form className="article-form" onSubmit={ this.handleSubmit }>

                  <Form.Group as={ Row } className="article-num-row" controlId="articleId">
                    <Form.Label className="article-num-label">
                      Username:
                    </Form.Label>

                    <Form.Control plaintext
                      readOnly
                      className="article-num-field"
                      value={ this.state.userName } />
                  </Form.Group>

                  <Form.Group className="article-subject-group" controlId="kbSubject">
                    <Form.Label>
                      Article Subject
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.kbSubject }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a title for this article.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="article-content-group" controlId="articleContent">
                    <Form.Label>
                      Article Content
                    </Form.Label>
                    <Form.Control as="textarea"
                      rows="10"
                      className="article-content"
                      size="lg"
                      value={ this.state.articleContent }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter the content of the article you want to create.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="article-attachment-group" controlId="file">
                    <Form.Label>
                      Attachment
                    </Form.Label>
                    <Form.Control type="file" onChange={ this.handleFileChange } />
                    <Form.Text className="text-muted">
                      Please include any attachments as needed.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="article-btn-group">
                    <LoaderButton block
                      size="lg"
                      disabled={ !this.validateForm() }
                      variant="primary"
                      type="submit"
                      isLoading={ this.state.isLoading }
                      text="Save"
                      loadingText="Saving..."
                    />
                    { /* Add a delete button here */ }
                    <LoaderButton block
                      size="lg"
                      disabled={ !this.validateForm() }
                      variant="danger"
                      isLoading={ this.state.isDeleting }
                      onClick={ this.handleDelete }
                      text="Delete"
                      loadingText="Deleting..."
                    />
                  </Form.Group>
                </Form>
              }
            </Container>
                </div>
        </Media>
      </article>
    );
  }
}




















